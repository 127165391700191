import React from 'react'
import '../styles/buynow.sass'

export const AuthorPage = () => {
  return (
    <div className="buy">
      <p>MADAM is currently available everywhere books are sold.
        THE RUINS is currently available to pre-order in the UK and US.</p>
      <div className="button-container">
        <a
          target="_blank"
          href="https://www.quercusbooks.co.uk/contributor/phoebe-wynne/"
          className="buy-option"
        >
          UK Author Page
        </a>
        <a
          target="_blank"
          href="https://us.macmillan.com/author/phoebewynne"
          className="buy-option"
        >
          US Author Page
        </a>
      </div>
    </div>
  )
}

export default AuthorPage
