import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Navbar from '../components/Navbar'
import '../styles/about.sass'
import Squiggle from '../img/squiggly2.png'
import BlogRoll from '../components/BlogRoll'
import Footer from '../components/Footer'
import { Link } from 'gatsby'
import AuthorPage from '../components/AuthorPage'

export const AboutPageTemplate = ({
  title,
  content,
  image,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Navbar showSub={false} showMenu={true} bold />
      <section className="section section--gradient">
        <div className="container" style={{ maxWidth: '1024px' }}>
          <div className="content">
            <h1
              className="text-center about-title"
              style={{ textTransform: 'uppercase' }}
            >
              {title}
            </h1>
          </div>
          <div className="columns" style={{ flexWrap: 'wrap' }}>
            <div
              className="column ph-image-widesceen"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                className="phoebe-image"
                style={{
                  backgroundImage: `url(${image.childImageSharp.fluid.src})`,
                }}
              ></div>
            </div>
            <div className="column">
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
          <div className="section section-container">
            <img className="squiggle" src={Squiggle}></img>
          </div>
          <div className="section section-container">
            <h2
              className="title is-size-5"
              style={{ textTransform: 'uppercase' }}
            >
              BLOG
            </h2>
          </div>
          <BlogRoll />
          <div className="section section-container">
            <img className="squiggle" src={Squiggle}></img>
          </div>
          <div className="section section-container">
            <AuthorPage />
          </div>
          <div className="section section-container">
            <h2
              className="title is-size-5"
              style={{ textTransform: 'uppercase' }}
            >
              SOCIAL
            </h2>
            <Link
              href="http://www.instagram.com/phoebewynnewrites"
              className="social-link"
              target="_blank"
            >
              Follow on Instagram →
            </Link>
            <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            <div class="elfsight-app-73594728-a079-4e62-b543-9897187b7aaa insta-feed"></div>
            <Link
              className="social-link"
              href="http://www.twitter.com/phoebewynne"
              target="_blank"
            >
              Follow on Twitter →
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
